<template>
  <BoothRenderer :design="design" />
</template>

<script>
import BoothRenderer from '../../components/BoothRenderer'

export default {
  name: 'Tour',
  components: { BoothRenderer },
  head() {
    return {
      title: this.$t('exhibition.title'),
    }
  },
  computed: {
    design () {
      return this.$route.params?.design
    }
  },
}
</script>

<style>

</style>