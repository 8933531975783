<template>
  <div>
    <div style="padding:56.25% 0 0 0;position:relative;">
      <iframe
        :src="source"
        style="position:absolute;top:0;left:0;width:100%;height:100%;"
        frameborder="0"
        allow="autoplay; fullscreen"
        allowfullscreen
      ></iframe>
    </div>
    <v-toolbar flat color="transparent" :dense="$vuetify.breakpoint.xsOnly">
      <v-spacer />
      <span v-if="$vuetify.breakpoint.smAndUp" class="caption"
        >Alternative Feeds:
      </span>
      <v-btn-toggle
        v-model="selectedLangIndex"
        group
        :dense="$vuetify.breakpoint.xsOnly"
        mandatory
      >
        <v-btn
          v-for="source in languages"
          :key="source.text"
          :small="$vuetify.breakpoint.xsOnly"
        >
          {{ source.text }}
        </v-btn>
      </v-btn-toggle>
      <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
    </v-toolbar>
  </div>
</template>

<script>
export default {
  name: 'Day',
  data() {
    return {
      languages: [
        { text: 'English', value: 'en' },
        { text: 'Spanish', value: 'es' }
        //{ text: 'Chinese', value: 'zh' }
      ],
      selectedLangIndex: 0,
      videos: {
        '1': {
          en:
            'https://player.vimeo.com/video/639579969?h=b18120672d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
          es:
            'https://player.vimeo.com/video/639585160?h=17dd782e0b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
          zh:
            'https://player.vimeo.com/video/639574573?h=5682d198c0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
        },
        '2': {
          en:
            'https://player.vimeo.com/video/639625611?h=7a097e1b5f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
          es:
            'https://player.vimeo.com/video/641100027?h=45ead66764&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
          zh:
            'https://player.vimeo.com/video/639611931?h=692856c868&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
        },
        '3': {
          en:
            'https://player.vimeo.com/video/639667362?h=1a23b97522&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
          es:
            'https://player.vimeo.com/video/639669682?h=105e93252c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
          zh:
            'https://player.vimeo.com/video/639658138?h=5085ac2552&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
        }
      }
    };
  },
  computed: {
    selectedLang() {
      return this.languages[this.selectedLangIndex].value;
    },
    source() {
      if (this.videos[this.$route.params.day]) {
        return (
          this.videos[this.$route.params.day][this.selectedLang] ||
          Object.values(this.videos[this.$route.params.day])[0]
        );
      }
      return null;
    }
  }
};
</script>
