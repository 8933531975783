<template>
  <div
    ref="outerContainer"
    v-resize="onResize"
    class="outerContainer"
    :style="outerContainer"
    @mousedown="mouseDownHandler"
    @mousemove="mouseMoveHandler"
    @mouseup="mouseUpHandler"
  >
    <div v-if="inContW > outContW" class="swipeIndicator">
      <v-icon size="36" color="white">
        mdi-gesture-swipe
      </v-icon>
    </div>
    <div ref="innerContainer" class="innerContainer" :style="innerContainer" />

    <v-dialog
      v-model="galleryDialog"
      :max-width="$vuetify.breakpoint.mdAndUp ? '60%' : '100%'"
    >
      <v-card>
        <template v-for="(item, index) in visibleGallery">
          <template v-if="index === galleryIndex && galleryDialog">
            <v-img
              contain
              v-if="item.type === 'image'"
              :src="item.source.toString().replace('{BASE}', baseUrl + design)"
              :key="item.source"
            />
            <Plyr
              v-else-if="item.type === 'video'"
              :source="item.source"
              :options="plyrOpts"
              :key="item.source.toString()"
            >
              <video playsinline />
            </Plyr>
          </template>
        </template>
        <div
          class="d-flex align-center justify-center"
          v-if="checkVisibilityForArray(gallery).length > 1"
        >
          <v-btn
            icon
            v-for="(item, index) in checkVisibilityForArray(gallery)"
            :key="index + '_sli_btn'"
            :color="index === galleryIndex ? 'primary' : 'grey lighten-2'"
            @click="galleryIndex = index"
            ><v-icon size="18">mdi-circle</v-icon></v-btn
          >
        </div>
        <v-btn
          fab
          color="white"
          absolute
          class="mt-10"
          top
          right
          @click="galleryDialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="videoDialog"
      :max-width="$vuetify.breakpoint.mdAndUp ? '60%' : '100%'"
    >
      <v-card>
        <Plyr :source="videoSource" :options="plyrOpts">
          <video playsinline />
        </Plyr>
        <v-btn
          fab
          color="white"
          absolute
          class="mt-10"
          top
          right
          @click="videoDialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="imageDialog"
      :max-width="$vuetify.breakpoint.mdAndUp ? '60%' : '100%'"
    >
      <v-card>
        <v-img :src="imageSource" />
        <v-btn
          fab
          color="white"
          absolute
          class="mt-10"
          top
          right
          @click="imageDialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

    <v-dialog v-model="fileDialog" max-width="600" scrollable>
      <v-card>
        <v-toolbar color="grey darken-3" dark>
          <v-toolbar-title>Brochures & Files</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="fileDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-0 mt-2" style="max-height: 500px;">
          <template v-for="file in checkVisibilityForArray(files)">
            <v-list-item
              :key="file.url"
              :href="file.url.replace('{BASE}', baseUrl + design)"
              target="_blank"
            >
              <v-list-item-avatar style="justify-content: center">
                <v-icon>
                  {{ file.icon || 'mdi-file' }}
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{
                  file.title ||
                    file.url.substring(file.url.lastIndexOf('/') + 1)
                }}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon>
                  <v-icon color="grey lighten-1">mdi-download</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="contactDialog" max-width="400">
      <v-card>
        <v-toolbar color="grey darken-3" dark>
          <v-toolbar-title>Contact</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="contactDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container>
          <v-row>
            <v-col cols="12">
              <template v-for="contact in checkVisibilityForArray(contacts)">
                <v-list-item :key="contact.name" three-line>
                  <v-list-item-avatar
                    size="56"
                    color="grey lighten-2"
                    style="justify-content: center"
                  >
                    {{ getInitials(contact) }}
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">{{
                      contact.name
                    }}</v-list-item-title>
                    <v-list-item-subtitle v-if="contact.title">{{
                      contact.title
                    }}</v-list-item-subtitle>
                    <v-list-item-subtitle v-if="contact.email"
                      ><a
                        class="text-decoration-none"
                        :href="'mailto:' + contact.email"
                        ><v-icon left size="14">mdi-email</v-icon
                        >{{ contact.email }}</a
                      ></v-list-item-subtitle
                    >
                    <v-list-item-subtitle v-if="contact.tel"
                      ><a
                        class="text-decoration-none"
                        :href="'tel:' + contact.tel"
                        ><v-icon left size="14">mdi-phone</v-icon
                        >{{ contact.tel }}</a
                      ></v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-divider :key="contact.name + '-d'" />
              </template>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" v-if="contactInfo.website">
              <v-btn
                block
                depressed
                x-large
                color="primary"
                dark
                :href="getWebsiteUrl(contactInfo.website)"
                target="_blank"
              >
                <v-icon left size="22">mdi-web</v-icon>
                Visit Website
              </v-btn>
              <template v-for="(button, index) in contactInfo.links || []">
                <v-btn
                  :key="index"
                  block
                  text
                  small
                  :href="button.href"
                  target="_blank"
                  class="my-1"
                >
                  {{ button.text }}
                </v-btn></template
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <div
      class="legend d-flex align-center justify-center"
      v-if="design === 'exhibition'"
    >
      <v-sheet elevation="10" color="white" rounded class="pr-3 pl-5 py-1">
        <v-chip-group
          column
          class="justify-center"
          v-if="$vuetify.breakpoint.mdAndUp || showLegend"
        >
          <v-chip
            v-for="(booth, index) in booths"
            :key="booth.to"
            pill
            :to="'/exhibition/' + booth.to"
          >
            <v-avatar
              left
              :color="booth.color"
              class="white--text font-weight-bold"
              >{{ index + 1 }}</v-avatar
            >
            {{ booth.name }}
          </v-chip>
        </v-chip-group>
        <v-btn small v-else text @click="showLegend = true">
          Show Legend
        </v-btn>
        <v-btn
          icon
          absolute
          bottom
          right
          @click="showLegend = false"
          v-if="$vuetify.breakpoint.smAndDown && showLegend"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { renderControl } from '../lib/boothrenderer/js/brenderer';
import Plyr from './Plyr';
import { initials } from '../lib/utils';
import { find } from 'lodash';

export default {
  name: 'BoothRenderer',
  components: {
    Plyr
  },
  props: {
    design: {
      type: String,
      default: 'exhibition'
    }
  },
  data() {
    return {
      booths: [
        { name: 'Medtronic', color: '#002CB9', to: 'medtronic' },
        {
          name: 'MicroVention',
          color: '#002CB9',
          to: 'microvention'
        },
        { name: 'Balt', color: '#49A3E8', to: 'balt' },
        { name: 'Siemens', color: '#49A3E8', to: 'siemens' },
        { name: 'Stryker', color: '#49A3E8', to: 'stryker' },
        {
          name: 'Cerus',
          color: '#4D4D4D',
          to: 'cerus'
        },
        { name: 'Cerenovus', color: '#4D4D4D', to: 'cerenovus' },
        { name: 'Phenox', color: '#4D4D4D', to: 'phenox' },
        { name: 'Vesalio', color: '#4D4D4D', to: 'vesalio' },
        { name: 'Philips', color: '#4D4D4D', to: 'philips' }
      ],
      showLegend: false,
      videoDialog: false,
      videoSource: {
        type: 'video',
        sources: []
      },
      imageDialog: false,
      imageSource: '',
      fileDialog: false,
      files: [],
      contactDialog: false,
      contacts: [],
      contactInfo: {},
      galleryDialog: false,
      gallery: [],
      galleryIndex: 0,
      window: {
        width: 0,
        height: 0
      },
      inContW: 0,
      outContW: 0,
      isDragging: false,
      scrollPos: {
        left: 0,
        x: 0
      },
      id: 'remote-adapter',
      baseUrl: process.env.VUE_APP_EXHIBITON_CDN,
      plyrOpts: {
        ratio: '16:9',
        autoplay: true,
        autopause: true,
        clickToPlay: false,
        hideControls: false
      }
    };
  },
  computed: {
    currentBooth() {
      return find(this.booths, { to: this.design });
    },
    visibleGallery() {
      return this.checkVisibilityForArray(this.gallery).map(item => {
        if (item.type === 'video') {
          item.source = this.getVideoSources(item.source);
        }
        return item;
      });
    },
    calcHeight() {
      return (
        this.window.height - (this.$vuetify.breakpoint.smAndDown ? 56 : 64)
      );
    },
    containerRatio() {
      return this.window.width / this.calcHeight;
    },
    scale() {
      /*
      if (this.containerRatio > 16/9) {
        return this.window.width / 1920
      }
      */
      return this.calcHeight / 1080;
    },
    outerContainer() {
      const style = {
        height: this.calcHeight + 'px'
      };

      if (this.inContW > this.outContW) {
        if (this.isDragging) {
          style.cursor = 'grabbing';
        } else {
          style.cursor = 'grab';
        }
      }

      return style;
    },
    innerContainer() {
      const shouldCenter = this.inContW < this.outContW;
      const marginAmount = (this.outContW - this.inContW) / 2;

      return {
        marginLeft: shouldCenter ? marginAmount + 'px' : 0,
        transform: `scale(${this.scale})`
      };
    },
    ...mapState({
      user: s => s.auth?.user,
      userCountry: s => s.auth.user?.country
    })
  },
  watch: {
    design() {
      this.cleanUp();
      this.render();
    }
  },
  mounted() {
    this.render();
  },
  created() {
    if (this.$route.query.exhibitionCdn) {
      this.baseUrl = this.$route.query.exhibitionCdn;
    }
  },
  sockets: {},
  methods: {
    mouseDownHandler(e) {
      this.isDragging = true;
      this.scrollPos.left = this.$refs.outerContainer.scrollLeft;
      // this.scrollPos.top = this.$refs.outerContainer.scrollTop
      this.scrollPos.x = e.clientX;
      // this.scrollPos.y = e.clientY
    },
    mouseMoveHandler(e) {
      if (this.isDragging) {
        const dx = e.clientX - this.scrollPos.x;
        // const dy = e.clientY - this.scrollPos.y
        this.$refs.outerContainer.scrollLeft = this.scrollPos.left - dx;
        // this.$refs.outerContainer.scrollTop = this.scrollPos.top - dy
      }
    },
    mouseUpHandler() {
      this.isDragging = false;
    },
    centerContainer() {
      this.$refs.outerContainer.scrollLeft =
        (this.$refs.outerContainer.scrollWidth -
          this.$refs.outerContainer.clientWidth) /
        2;
    },
    onResize() {
      this.window.height = window.innerHeight;
      this.window.width = window.innerWidth;
      this.inContW = this.$refs.innerContainer.clientWidth * this.scale;
      this.outContW = this.$refs.outerContainer.clientWidth;

      setTimeout(() => {
        this.centerContainer();
      });
    },
    async render() {
      await renderControl(
        this.baseUrl + this.design,
        this.$refs.innerContainer,
        true,
        this
      );
      this.centerContainer();
      this.$socket.emit('call', 'logs.log', {
        type: 'visit_stand',
        stand: this.design
      });
    },
    async callMethod(requestInfo, sender, name, params) {
      if (name === 'navigate') {
        this.$router.push({ path: params, query: this.$route.query });
        return 'OK';
      } else if (name === 'navigateToDesign') {
        console.log(params);
        this.$router.push({
          path: `/exhibition/${params}`,
          query: this.$route.query
        });
        return 'OK';
      } else if (name === 'openVideo') {
        this.videoSource = {
          type: 'video',
          ...params
        };
        this.videoDialog = true;
        this.$socket.emit('call', 'logs.log', {
          type: 'open_video',
          stand: this.design
        });
      } else if (name === 'openImage') {
        this.imageSource = params;
        this.imageDialog = true;
        this.$socket.emit('call', 'logs.log', {
          type: 'open_image',
          stand: this.design
        });
      } else if (name === 'openFiles') {
        this.files = params;
        this.fileDialog = true;
        this.$socket.emit('call', 'logs.log', {
          type: 'open_files',
          stand: this.design
        });
      } else if (name === 'openContact') {
        this.contacts = params.contacts;
        this.contactInfo = params.info;
        this.contactDialog = true;
        this.$socket.emit('call', 'logs.log', {
          type: 'open_contact',
          stand: this.design
        });
      } else if (name === 'openGallery') {
        this.gallery = params;
        this.galleryDialog = true;
        this.$socket.emit('call', 'logs.log', {
          type: 'open_gallery',
          stand: this.design
        });
      } else {
        console.log(requestInfo, sender, name, params);
      }
    },
    checkVisibilityForArray(array) {
      return array.filter(i => this.checkVisibility(i));
    },
    checkVisibility({ only, except }) {
      if (only) {
        if (only.includes(this.userCountry)) {
          return true;
        }
        return false;
      }

      if (except) {
        if (except.includes(this.userCountry)) {
          return false;
        }
      }

      return true;
    },
    getInitials(contact) {
      return initials(contact.email, contact.name);
    },
    getWebsiteUrl(website) {
      if (typeof website === 'string') return website;
      else if (typeof website === 'object') {
        if (website[this.userCountry]) {
          return website[this.userCountry];
        }
        return website.default;
      }
    },
    getVideoSources(source) {
      if (typeof source === 'string') {
        return {
          type: 'video',
          sources: [
            {
              type:
                'video/' +
                source
                  .split(/[#?]/)[0]
                  .split('.')
                  .pop()
                  .trim(),
              src: source?.replace('{BASE}', this.baseUrl + this.design)
            }
          ]
        };
      } else if (typeof source === 'object') {
        source.src = source.src?.replace('{BASE}', this.baseUrl + this.design);
        return {
          type: 'video',
          sources: [source]
        };
      }
    },
    cleanUp() {
      this.videoSource = {};
      this.videoDialog = false;
      this.imageSource = '';
      this.imageDialog = false;
      this.files = [];
      this.fileDialog = false;
      this.contacts = [];
      this.contactInfo = {};
      this.contactDialog = false;
      this.gallery = [];
      this.galleryIndex = 0;
      this.galleryDialog = false;
      this.chatDialog = false;
      this.janusDialog = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.outerContainer {
  position: relative;
  overflow: auto hidden;
  width: '100%';
  user-select: none;
  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  background: #222;
}

.innerContainer {
  position: relative;
  width: 1920px;
  height: 1080px;
  transform-origin: left top;
  overflow: hidden;
}

.swipeIndicator {
  position: fixed;
  bottom: 60px;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 99;
  pointer-events: none;
  user-select: none;
}

.legend {
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
}
</style>
