<template>
  <BoothRenderer design="lobby" />
</template>

<script>
import BoothRenderer from '../components/BoothRenderer';

export default {
  name: 'Home',
  components: { BoothRenderer },
  head() {
    return {
      title: this.$t('exhibition.foyer')
    };
  }
};
</script>

<style></style>
